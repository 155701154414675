import { useBreakpoints, createSharedComposable } from "@vueuse/core";
import { stringifiedMediaBreakpointsResolver } from "@/constants/mediaBreakpointsResolver";
import { computed } from "vue";

export const useIsMobileHandler = createSharedComposable(() => {
  const breakpoints = useBreakpoints(stringifiedMediaBreakpointsResolver);

  const isMobileComputed = computed(() => !breakpoints.md.value);

  return {
    isMobile: isMobileComputed,
  };
});
